import axios from 'axios';
import type { AxiosError } from 'axios';
import { ElMessage } from 'element-plus';
import { getToken, removeToken, removeUserInfo } from './auth';

// create an axios instance
const service = axios.create({
  baseURL: '',
  timeout: 1000 * 60 * 5, // request timeout
  withCredentials: false
});

service.interceptors.request.use(
  async config => {
    if (!config.params) config.params = {};
    config.params._xhr = true;
    config.headers['x-nicecuda-token'] = getToken();
    return config;
  },
  error => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    if (!(response.data as any).code) return response.data;
    return response.data;
  },
  (error: AxiosError) => {
    console.log('err' + error); // for debug
    if (error.response?.status === 401) {
      removeToken();
      removeUserInfo();
      window.location.reload();
    } else {
      if (error.code !== 'ERR_CANCELED') {
        ElMessage({
          message: error.message,
          type: 'error'
        });
      }
    }
    return Promise.reject(error);
  }
);

export default service;
