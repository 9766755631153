export const arithmeticManagement = {
  btns: {
    newInstance: 'New'
  },
  overview: {
    title: 'Overview',
    resourcesOfNumber: 'Resources',
    running: 'Running',
    academicAcceleration: 'Academic Acceleration',
    academicAccelerationAbility: 'Academic acceleration ability, after turning on can improve part of the academic resources platform access, download acceleration',
    accelerationSettings: 'Acceleration settings'
  },
  compute: {
    title: 'My Compute',
    immediateUse: 'Use Now',
    totalCu: 'Total Compute',
    used: 'Used',
    left: 'Remaining',
    date: 'Valid Date'
  },
  instance: {
    title: 'Instances',
    gpuMemory: 'GPU Memory',
    arithmeticPower: 'Arithmetic power',
    systemDisk: 'System Disk',
    network: 'Network',
    cloudDisk: 'Cloud disk',
    expired: 'Expired',
    validTime: 'Due Date',
    publicIp: 'Public IP:',
    arithmeticConnection: 'Arithmetic connection',
    statusDesc: 'The instance `Name` is in the `Status` state and this operation is not supported.',
    more: 'More',
    turnOff: 'Shut down',
    turnOn: 'Turn on',
    restart: 'Restart',
    renew: 'Renew',
    hasNoCanUsedCu: 'No CU available.',
    cloudDiskExpansion: 'Cloud disk expansion',
    destroy: 'Destroy',
    cancelBidding: 'Cancel bidding',
    loginService: {
      username: 'Username',
      password: 'Password'
    },
    destroyDialog: {
      title: 'Destroy',
      desc: 'Are you sure you want to destroy the instance: \'Instance\'?',
      sumbitSuccess: 'Destroyed successfully.'
    },
    restartDialog: {
      title: 'Restart',
      desc: 'Make sure you want to restart the instance: \'Instance\'?',
      sumbitSuccess: 'Submission successful, restarting.'
    },
    turnOffDialog: {
      title: 'Shut Down',
      tip: 'You will perform a <span style="font-weight: bold;">Shut Down</span> operation for the following services, please confirm.',
      open: 'Open',
      close: 'Collapse',
      table: {
        name: 'Name/GPU',
        region: 'Region',
        powerConfig: 'Compute',
        cloudDisk: 'System Disk'
      },
      sumbitSuccess: 'Submission successful. Shutting down.'
    },
    startUpDialog: {
      title: 'Start Up',
      tip: 'You will perform a <span style="font-weight: bold;">Start Up</span> operation for the following services, please confirm.',
      open: 'Open',
      close: 'Collapse',
      table: {
        name: 'Name/GPU',
        region: 'Region',
        powerConfig: 'Compute',
        cloudDisk: 'System Disk'
      },
      sumbitSuccess: 'Submission successful. Powering up.'
    },
    renewDialog: {
      title: 'Renew',
      titleCanUseCu: '(Available for {cu}CU)',
      tip: 'You will perform a <span style="font-weight: bold;">renewal</span> operation on the following services. After renewal, the instance can run until {time}. Please confirm.',
      open: 'Open',
      close: 'Collapse',
      table: {
        name: 'Name/GPU',
        region: 'Region',
        powerConfig: 'Compute',
        cloudDisk: 'System Disk'
      },
      sumbitSuccess: 'Submission successful. Powering up.',
      form: {
        useCu: 'CU to be Used'
      }
    },
    confirmButton: 'Confirm',
    cancelButton: 'Cancel'
  },
  common: {
    refreshSuccess: 'Refresh Successful',
    refresh: 'Refresh'
  }
};
