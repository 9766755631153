export enum EnumSource {
  nice_cuda = 'NiceCUDA',
  ALL = 'All'
}
export interface FeedbackData {
  feedback: string;
  score: number;
  source?: EnumSource;
}

