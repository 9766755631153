import { computed } from 'vue';
import type { BaseResponse } from '../http/response';
import i18n from '@/lang';
export interface GetApplicationsRequest {
  application_type?: EnumApplicationCategory;
}
export enum EnumRegion {
  nc_shanghai = 'nc-shanghai',
  nc_us_west = 'us-west'
}
export enum EnumApplicationCategory {
  all = 'all',
  single = 'single',
  cluster = 'cluster'
}
export const DictEnumRegion = computed(() => {
  return {
    [EnumRegion.nc_shanghai]: i18n.global.t(`common.region.${EnumRegion.nc_shanghai}`),
    [EnumRegion.nc_us_west]: i18n.global.t(`common.region.${EnumRegion.nc_us_west}`)
  };
});
export enum EnumApllicationType {
  ai_model = 'ai-model',
  ai_framework = 'ai-framework',
  base_env = 'basic-environment',
  community_application = 'community-application',
  custom_application = 'custom-application'
}

export interface LoginServiceItem {
  'service_name': string;
  'icon_url': string;
  'public_url': string;
  'loginInfo'?: {
    'username': string;
    'password': string;
  }
}

export interface ApplicationItem {
  'group': EnumApllicationType;
  'active': boolean;
  'id': string;
  'name': string;
  'icon': string;
  'config_environment': string;
  'description': string;
  'min_system_disk_size': number;
  'min_gpu_count': number;
  'defailt_size': number;
  'create_at': number;
  'community_url': string;
  'guide_url': string;
  'type': EnumApplicationCategory;
  'login_services': Array<LoginServiceItem>;
}
export interface GetApplicationsResponseData {
  'applications': Array<ApplicationItem>;
}
export type GetApplicationsResponse = BaseResponse<GetApplicationsResponseData>
