export const instanceDetail = {
  instanceID: {
    title: 'Instance ID'
  },
  computeType: {
    title: 'Compute Type',
    disk: 'System Disk',
    network: 'Network'
  },
  applicationName: {
    title: 'App Name'
  },
  envConfig: {
    title: 'Environment'
  },
  createTime: {
    title: 'Create Time'
  },
  publicIP: {
    title: 'Public IP'
  },
  chart: {
    maiTitle: 'Monitor',
    max: 'Max:',
    min: 'Min:',
    avg: 'Avg:',
    cpuUtilizationChart: {
      title: 'CPU Utilization(100%)',
      desc: 'CPU utilization is collected and reported by the internal monitoring component of the server, which makes the data more accurate.'
    },
    memoryUsageChart: {
      title: 'Memory Usage(MB)',
      desc: 'The amount of memory used, excluding system cache and buffer occupied memory, relies on the monitoring component to install the collection.'
    },
    gpuMemoryUsageChart: {
      title: 'GPU Memory Usage(MB)',
      desc: 'GPU Memory Usage.'
    },
    gpuUsageChart: {
      title: 'GPU Utilization(%)',
      desc: 'GPU Utilization'
    }
  },
  computeConnection: {
    title: 'Compute Connection'
  },
  storageResource: {
    title: 'Storage Resource'
  },
  networkResource: {
    title: 'Network Resource',
    desc: 'Your network resources are used'
  }
};
