export const instanceDetail = {
  instanceID: {
    title: '实例ID'
  },
  computeType: {
    title: '算力类型',
    disk: '系统盘',
    network: '网络'
  },
  applicationName: {
    title: '应用名称'
  },
  envConfig: {
    title: '配置环境'
  },
  createTime: {
    title: '创建时间'
  },
  publicIP: {
    title: '公网IP'
  },
  chart: {
    maiTitle: '监控',
    max: '最大值：',
    min: '最小值：',
    avg: '平均值：',
    cpuUtilizationChart: {
      title: 'CPU利用率（%）',
      desc: 'CPU利用率是通过服务器内部监控组件采集上报，数据更加精准。'
    },
    memoryUsageChart: {
      title: '内存使用量（MB）',
      desc: '使用的内存量，不包括系统缓存和缓存区占用内存，依赖监控组件安装采集。'
    },
    gpuMemoryUsageChart: {
      title: 'GPU显存使用量（MB）',
      desc: 'GPU内存使用量'
    },
    gpuUsageChart: {
      title: 'GPU使用率（%）',
      desc: 'GPU使用率'
    }
  },
  computeConnection: {
    title: '算力连接'
  },
  storageResource: {
    title: '存储资源'
  },
  networkResource: {
    title: '网络资源',
    desc: '您的网络资源已使用'
  }
};
