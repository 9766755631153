import { EnumLanguage } from '@/enums/language';
import { EnumProfession } from '@/enums/profession';
import { EnumStep } from '@/enums/step';
import { EnumApplicationCategory, EnumRegion } from '@/interface/applications/getApplications';
import { EnumInstanceStatus } from '@/interface/primary-pool/getInstances';
export const common = {
  appName: 'NiceGPU',
  meta: {
    title: {
      'customer-review': 'Customer Review AI Analysis - SellingPilot',
      'plugin-home': 'SellingPilot - Your AI-powered assistant for e-commerce.'
    }
  },
  unit: {
    hour: 'hours',
    days: 'days',
    pcs: 'pcs'
  },
  language: {
    [EnumLanguage.LAN_EN_US]: 'English',
    [EnumLanguage.LAN_CHINESE]: 'Simplified Chinese',
    [EnumLanguage.LAN_TRANDITIONAL_CHINESE]: 'Traditional Chinese'
  },
  noRatingTooltip: 'There are no customer reviews to analyze.',
  step: {
    [EnumStep.PREPARING_DATA]: 'Preparing Data',
    [EnumStep.ANALYZING]: 'Analyzing',
    [EnumStep.LANGUAGE]: 'Language',
    [EnumStep.COMPLETED]: 'Completed'
  },
  applicationCategory: {
    [EnumApplicationCategory.all]: 'All Applications',
    [EnumApplicationCategory.cluster]: 'Cluster Applications',
    [EnumApplicationCategory.single]: 'Single Applications'
  },
  instanceStatus: {
    [EnumInstanceStatus.closed]: 'Closed',
    [EnumInstanceStatus.closing]: 'Closing',
    [EnumInstanceStatus.initializing]: 'Initializing',
    [EnumInstanceStatus.running]: 'Running',
    [EnumInstanceStatus.startingUp]: 'Starting Up'
  },
  region: {
    [EnumRegion.nc_shanghai]: 'Shanghai',
    [EnumRegion.nc_us_west]: 'Western United States'
  },
  profession: {
    [EnumProfession.SOFTWARE_ENGINEER]: 'Software Engineer',
    [EnumProfession.PRODUCT_MANAGER]: 'Product Manager',
    [EnumProfession.DESIGNER]: 'Designer',
    [EnumProfession.STUDENT]: 'Student',
    [EnumProfession.OPERATIONS_STAFF]: 'Operations Staff',
    [EnumProfession.MARKETING_PERSONNEL]: 'Marketing Personnel',
    [EnumProfession.FREELANCER]: 'Freelancer',
    [EnumProfession.OTHER]: 'Other'
  },
  breadcrumbs: {
    arithmeticManagement: 'Compute Management',
    newService: 'New Instance',
    login: 'Login',
    instanceDetail: 'Instance Detail',
    joinBeta: 'Join Beta List',
    contactUs: 'Contact Us',
    dashboardView: 'NiceGPU'
  },
  menus: {
    title: 'High-Performance Apps',
    arithmeticManagement: 'Compute Management'
  },
  commingSoon: 'Comming soon',
  noData: 'No Data',
  feedback: {
    title: 'I Need Help',
    productSurveyTitle: 'Product Survey',
    feedbackFormLabel: 'Do you have any suggestions to improve NiceGPU? What features or functionality would you like to see added or improved?',
    feedbackFormPlaceholder: 'Thank you for your suggestions to help us improve the products',
    feedbackFormRequiredWorks: 'Please input suggestions',
    rateFormLabel: 'On a scale of 1 to 5, please select a number that indicates how satisfied you are with the functionality of NiceGPU (1 being very dissatisfied, 5 being very satisfied).',
    rateFormRequiredWorks: 'Please select a number',
    rateNotLikelyAtAll: 'Not likely at all',
    rateExtremelyLikely: 'Extremly likely',
    submitBtn: 'Submit',
    successTitle: 'Thank you for your feedback!',
    successCloseBtn: 'OK'
  },
  helpCenter: {
    title: 'Help Center'
  },
  copySuccess: 'Copy Successfully',
  copyError: 'Copy Failure',
  footer: {
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    contactUs: 'Contact Us',
    aboutUs: 'About Us'
  }
};
