export const arithmeticManagement = {
  btns: {
    newInstance: '新建'
  },
  overview: {
    title: '概览',
    resourcesOfNumber: '资源数',
    running: '运行中',
    academicAcceleration: '学术加速',
    academicAccelerationAbility: '学术加速能力，开启后可提高部分学术资源平台访问、下载加速',
    accelerationSettings: '加速设置'
  },
  compute: {
    title: '我的算力',
    immediateUse: '立即使用',
    totalCu: '总算力',
    used: '已使用',
    left: '可使用',
    date: '有效日期'
  },
  instance: {
    title: '实例',
    gpuMemory: '显存',
    arithmeticPower: '算力',
    systemDisk: '系统盘',
    network: '网络',
    cloudDisk: '云硬盘',
    expired: '已到期',
    validTime: '到期时间',
    publicIp: '公网IP：',
    arithmeticConnection: '算力连接',
    statusDesc: '实例`Name`处于`Status`状态，不支持该操作。',
    more: '更多',
    turnOff: '关机',
    turnOn: '开机',
    restart: '重启',
    cloudDiskExpansion: '云硬盘扩容',
    destroy: '销毁',
    renew: '续费',
    hasNoCanUsedCu: '没有可用CU。',
    cancelBidding: '取消竞价',
    loginService: {
      username: '用户名',
      password: '密码'
    },
    destroyDialog: {
      title: '销毁',
      desc: '确定要销毁实例：\'Instance\'？',
      sumbitSuccess: '销毁成功。'
    },
    restartDialog: {
      title: '重启',
      desc: '确定要重启实例：\'Instance\'?',
      sumbitSuccess: '提交成功，正在重启。'
    },
    turnOffDialog: {
      title: '关机',
      tip: '您将对下列服务执行<span style="font-weight: bold;">关机</span>操作，请确认。',
      open: '展开',
      close: '收起',
      table: {
        name: '实例名称/GPU',
        region: '地域',
        powerConfig: '算力配置',
        cloudDisk: '系统盘'
      },
      sumbitSuccess: '提交成功，正在关机。'
    },
    startUpDialog: {
      title: '开机',
      tip: '您将对下列服务执行<span style="font-weight: bold;">开机</span>操作，请确认。',
      open: '展开',
      close: '收起',
      table: {
        name: '实例名称/GPU',
        region: '地域',
        powerConfig: '算力配置',
        cloudDisk: '系统盘'
      },
      sumbitSuccess: '提交成功，正在开机。'
    },
    renewDialog: {
      title: '续费',
      titleCanUseCu: '（可用{cu}CU）',
      tip: '您将对下列服务执行<span style="font-weight: bold;">续费</span>操作，续费后实例可运行至{time}，请确认。',
      open: '展开',
      close: '收起',
      table: {
        name: '实例名称/GPU',
        region: '地域',
        powerConfig: '算力配置',
        cloudDisk: '系统盘'
      },
      sumbitSuccess: '续费成功。',
      form: {
        useCu: '使用CU'
      }
    },
    confirmButton: '确定',
    cancelButton: '取消'
  },
  common: {
    refreshSuccess: '刷新成功',
    refresh: '刷新'
  }
};
