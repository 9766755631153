export const login = {
  userNamePlaceholder: 'Please enter your user name',
  userNameRequiredWords: 'Please enter your user name',
  passwordPlaceholder: 'Please enter your login password',
  passwordRequiredWords: 'Please enter your login password',
  loginBtn: 'Sign In',
  logoutBtn: 'Logout',
  loginError: 'Incorrect user name or login password',
  loginSuccess: 'Login Successful',
  contactUs: {
    title: 'Contact us'
  },
  joinBeta: {
    title: 'Join our Beta List',
    content: {
      title: '<strong>Unlock the Power of Graphics Cards with Our Revolutionary Solution.</strong>',
      content: 'We\'re excited to introduce a game-changing way to unleash the full potential of graphics cards. Plus, get <strong>FREE credit</strong> to access powerful GPU resources.',
      end: '<strong>Join Our Beta Program Today!</strong>'
    },
    form: {
      emailPlaceholder: 'Enter your email',
      joinBetaBtn: 'Join Beta',
      emailRequired: 'Please enter your email',
      emailFormatError: 'Please enter the correct email address',
      submitSuccess: 'Submit successfully!',
      submitRepeated: 'This email already exists, please do not submit again!'
    },
    accountInfo: {
      submitSuccess: 'Welcome to the NiceGPU testing program!<br>If computational resources are available, we will allocate them to you for free.<br>If no resources are currently available, please wait patiently.',
      note: 'Please carefully save the following username and password as they will only be displayed once.',
      submitRepeated: 'This email already exists, please do not submit again!',
      userName: 'Username: ',
      password: 'Password: '
    }
  }
};
