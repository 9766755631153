import { EnumLanguage } from '@/enums/language';
import { EnumProfession } from '@/enums/profession';
import { EnumStep } from '@/enums/step';
import { EnumApplicationCategory, EnumRegion } from '@/interface/applications/getApplications';
import { EnumInstanceStatus } from '@/interface/primary-pool/getInstances';
export const common = {
  appName: 'NiceGPU',
  meta: {
    title: {
      'customer-review': 'Customer Review AI分析 - SellingPilot',
      'plugin-home': 'SellingPilot - Your AI-powered assistant for e-commerce.'
    }
  },
  unit: {
    hour: '小时',
    days: '天',
    pcs: '个'
  },
  language: {
    [EnumLanguage.LAN_EN_US]: '英文',
    [EnumLanguage.LAN_CHINESE]: '简体中文',
    [EnumLanguage.LAN_TRANDITIONAL_CHINESE]: '繁体中文'
  },
  noRatingTooltip: '没有客户评论,无法进行分析。',
  step: {
    [EnumStep.PREPARING_DATA]: '准备数据中',
    [EnumStep.ANALYZING]: '分析中',
    [EnumStep.LANGUAGE]: '生成多语言',
    [EnumStep.COMPLETED]: '分析完成'
  },
  instanceStatus: {
    [EnumInstanceStatus.closed]: '关机',
    [EnumInstanceStatus.closing]: '关机中',
    [EnumInstanceStatus.initializing]: '初始化中',
    [EnumInstanceStatus.running]: '运行中',
    [EnumInstanceStatus.startingUp]: '开机中'
  },
  applicationCategory: {
    [EnumApplicationCategory.all]: '所有应用',
    [EnumApplicationCategory.cluster]: '集群应用',
    [EnumApplicationCategory.single]: '单机应用'
  },
  region: {
    [EnumRegion.nc_shanghai]: '上海',
    [EnumRegion.nc_us_west]: '美国西部'
  },
  profession: {
    [EnumProfession.SOFTWARE_ENGINEER]: '软件工程师',
    [EnumProfession.PRODUCT_MANAGER]: '产品经理',
    [EnumProfession.DESIGNER]: '设计师',
    [EnumProfession.STUDENT]: '学生',
    [EnumProfession.OPERATIONS_STAFF]: '运营人员',
    [EnumProfession.MARKETING_PERSONNEL]: '市场营销人员',
    [EnumProfession.FREELANCER]: '自由职业者',
    [EnumProfession.OTHER]: '其他'
  },
  breadcrumbs: {
    arithmeticManagement: '算力管理',
    newService: '新建实例',
    login: '登录',
    instanceDetail: '实例详情',
    joinBeta: '加入测试计划',
    contactUs: '联系我们',
    dashboardView: 'NiceGPU'
  },
  commingSoon: '即将推出',
  menus: {
    title: '高性能应用服务',
    arithmeticManagement: '算力管理'
  },
  noData: '暂时没有数据',
  feedback: {
    title: '我需要帮助',
    productSurveyTitle: '产品调查',
    feedbackFormLabel: '您是否有任何关于NiceGPU的改进建议？有哪些功能或特性您希望添加或改善？',
    feedbackFormPlaceholder: '感谢您的建议帮助我们改进产品',
    feedbackFormRequiredWorks: '请输入您的建议',
    rateFormLabel: '请在1到5的评分中选择一个数字， 表示您对NiceGPU功能的满意度（1表示非常不满，5表示非常满意）。',
    rateFormRequiredWorks: '请选择一个数字',
    rateNotLikelyAtAll: '根本不喜欢',
    rateExtremelyLikely: '极度喜欢',
    submitBtn: '提交',
    successTitle: '感谢您的反馈!',
    successCloseBtn: '知道了'
  },
  helpCenter: {
    title: '帮助中心'
  },
  copySuccess: '复制成功',
  copyError: '复制失败',
  footer: {
    termsOfService: '服务条款',
    privacyPolicy: '隐私政策',
    contactUs: '联系我们',
    aboutUs: '关于我们'
  }
};
