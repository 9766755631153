export const newService = {
  compute: {
    totalCu: '总算力',
    used: '已使用',
    left: '可使用',
    date: '有效日期'
  },
  form: {
    appType: '应用类型',
    selectApp: {
      label: '选择应用',
      aiModel: 'AI模型',
      aiFrame: 'AI框架',
      baseEnv: '基础环境',
      communityApp: '社区应用',
      cluster: '集群'
    },
    computeSolution: {
      label: '选择算力',
      cuToBeUsed: '使用CU',
      resourceRequired: '请选择一个算力',
      resourceSoldOut: '算力已售罄',
      table: {
        soldOut: '已售罄',
        columnRegion: '地域',
        columnGPUType: 'GPU类型',
        columnGPUNum: 'GPU数量',
        columnCPUMemory: 'GPU内存',
        columnDisk: '系统盘',
        columnNetwork: '网络',
        columnCUH: 'CU/24小时',
        columnMaxTime: '最大可用时长',
        noData: '暂无可用算力。'
      }
    },
    name: {
      label: '实例名称',
      inputPlaceholder: '请输入实例名称',
      inputRequiredWords: '请输入实例名称'
    },
    protocol: {
      label: '协议',
      protocolRequiredWords: '请阅读相关协议并勾选同意',
      protocolPreWords: '我已阅读并同意',
      protocolName: 'NiceGPU云服务协议'
    },
    createSuccess: '创建成功'
  },
  createDialog: {
    title: '创建',
    tip: '您将创建以下实例，请确认。',
    open: '展开',
    close: '收起',
    table: {
      name: '实例名称/GPU',
      region: '地域',
      powerConfig: '算力配置',
      cloudDisk: '系统盘',
      maxAvailableTime: '最大可用时长',
      applicationName: '应用名称'
    },
    confirmButton: '确定',
    cancelButton: '取消'
  },
  bottomArea: {
    totalCu: '总计',
    createNow: '立即创建',
    cancel: '取消创建'
  }
};
