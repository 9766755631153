import { arithmeticManagement } from './arithmetic-management-en';
import { common } from './common-en';
import { contactUs } from './contact-us-en';
import { dashboard } from './dashboard-en';
import { instanceDetail } from './instance-detail-en';
import { joinBeta } from './join-beta-en';
import { login } from './login-en';
import { newService } from './new-service-en';
export const en = {
  arithmeticManagement,
  common,
  login,
  instanceDetail,
  newService,
  joinBeta,
  contactUs,
  dashboard
};
