export const newService = {
  compute: {
    totalCu: 'Total Compute',
    used: 'Used',
    left: 'Remaining',
    date: 'Valid Date'
  },
  form: {
    appType: 'Type',
    selectApp: {
      label: 'Application',
      aiModel: 'AI Model',
      aiFrame: 'AI Framework',
      baseEnv: 'Base Environment',
      communityApp: 'Community Application',
      cluster: 'Cluster'
    },
    computeSolution: {
      label: 'Selecting Compute',
      cuToBeUsed: 'CU to be Used',
      resourceRequired: 'Please select a compute',
      resourceSoldOut: 'The compute is sold out.',
      table: {
        soldOut: 'Sold-out',
        columnRegion: 'Region',
        columnGPUType: 'GPU Type',
        columnGPUNum: 'Number of GPUs',
        columnCPUMemory: 'GPU Memory',
        columnDisk: 'System Disk',
        columnNetwork: 'Network',
        columnCUH: 'CU/24h',
        columnMaxTime: 'Max Available Time',
        noData: 'There\'s no compute available.'
      }
    },
    name: {
      label: 'Name',
      inputPlaceholder: 'Please enter instance name',
      inputRequiredWords: 'Please enter instance name'
    },
    protocol: {
      label: 'Protocol',
      protocolRequiredWords: 'Please read the relevant agreements and check to agree',
      protocolPreWords: 'I have read and agree to the ',
      protocolName: 'NiceGPU Cloud Service Agreement'
    },
    createSuccess: 'Create Successful'
  },
  createDialog: {
    title: 'Create',
    tip: 'You will create the following instance, please confirm.',
    open: 'Open',
    close: 'Collapse',
    table: {
      name: 'Name/GPU',
      region: 'Region',
      powerConfig: 'Compute',
      cloudDisk: 'System Disk',
      maxAvailableTime: 'Max Available Time',
      applicationName: 'Application Name'
    },
    confirmButton: 'Confirm',
    cancelButton: 'Cancel'
  },
  bottomArea: {
    totalCu: 'Total',
    createNow: 'Create Now',
    cancel: 'Cancel'
  }
};
