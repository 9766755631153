<template>
  <div class="feedback-dialog-container">
    <el-dialog @close="onCloseDialog" class="feedback-dialog" :title="mainTitle" :close-on-click-modal="false" :center="true" :modal="true" v-model="feedbackVisible" :width="props.feedBackWidth || `50%`">
      <el-form
        :model="feedbackData"
        class="feedback-form"
        ref="feedbackFormRef"
        :rules="feedbackFormRule"
      >
        <el-form-item class="form-item-score" :label="$t('common.feedback.rateFormLabel')" prop="score">
          <el-rate @change="onRateChange" v-model="feedbackData.score" size="large" />
        </el-form-item>
        <el-form-item :label="$t('common.feedback.feedbackFormLabel')" prop="feedback">
          <el-input size="medium" type="textarea"
            :placeholder="$t('common.feedback.feedbackFormPlaceholder')" v-model="feedbackData.feedback"
            maxlength="2000" show-word-limit>
        </el-input>
        </el-form-item>
      </el-form>
      <el-button :loading="isSubmitting" type="primary" @click="onSubmitFeedbackFormClick">{{ $t('common.feedback.submitBtn') }}</el-button>
    </el-dialog>
    <el-dialog class="feedback-suc-dialog" :center="true" v-model="feedbackSucVisible" :width="props.feedBackWidth || `50%`">
      <SuccessFilled class="el-icon-success"></SuccessFilled>
      <div class="title">{{ $t('common.feedback.successTitle') }}</div>
      <el-button type="primary" @click="feedbackSucVisible = false">{{ $t('common.feedback.successCloseBtn') }}</el-button>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import { type FeedbackData, EnumSource } from '@/interface/feedback';
import { SuccessFilled } from '@element-plus/icons-vue';
import type { FormInstance, FormRules } from 'element-plus';
import { feedback } from '@/api/feedback';
import i18n from '@/lang';

interface Props {
  feedBackWidth?: string;
}
const props = withDefaults(defineProps<Props>(), {});

const firstImmediateTriggerFeedback = ref<boolean>(false);
const mainTitle = computed(() => {
  if (firstImmediateTriggerFeedback.value) {
    return i18n.global.t('common.feedback.productSurveyTitle');
  } else {
    return i18n.global.t('common.feedback.title');
  }
});

const feedbackFormRef = ref<FormInstance>();
const checkScore = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error(i18n.global.t('common.feedback.rateFormRequiredWorks')));
  } else {
    callback();
  }
};
const onRateChange = () => {
  feedbackFormRef.value?.validateField(['score']);
};
const feedbackFormRule = computed(() => {
  return reactive<FormRules<FeedbackData>>({
    score: [{
      required: true,
      validator: checkScore
    }]
  });
});
const feedbackData = reactive<FeedbackData>({
  feedback: '',
  score: 0,
  source: EnumSource.nice_cuda
});
const feedbackSucVisible = ref(false);
const feedbackVisible = ref(false);
const onFeedbackButtonClick = () => {
  firstImmediateTriggerFeedback.value = false;
  if (feedbackFormRef.value) {
    feedbackFormRef.value.resetFields();
  }
  feedbackVisible.value = true;
};
const onFeedbackTrigger = () => {
  firstImmediateTriggerFeedback.value = true;
  feedbackVisible.value = true;
};
const isSubmitting = ref(false);
const doFeedback = async() => {
  isSubmitting.value = true;
  const [res, err] = await feedback(feedbackData).then(res => [res, null]).catch(err => [null, err]);
  console.log(res, err);
  if (res !== null) {
    feedbackSucVisible.value = true;
    feedbackVisible.value = false;
  } else {
    console.log(err);
  }
  isSubmitting.value = false;
};
const onSubmitFeedbackFormClick = async() => {
  if (!feedbackFormRef.value) return;
  await feedbackFormRef.value.validate((valid, fields) => {
    if (valid) {
      doFeedback();
    } else {
      console.log('error submit!', fields);
    }
  });
};
const onCloseDialog = () => {
  if (firstImmediateTriggerFeedback.value) {
    //
  }
};

defineExpose({
  onFeedbackButtonClick,
  onFeedbackTrigger
});

</script>

<style lang="scss" src="./FeedBack.scss" scoped></style>
