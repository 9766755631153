<template>
  <div class="layout-side-menu">
    <el-menu
      :default-active="activeIndex"
      :collapse="isCollapse"
      background-color="#181818"
    >
      <el-menu-item v-for="(menu, index) in menuRoutes" :key="menu.name" :index="`${index}`" @click="onArithmeticManagementItemClick" >
        <el-icon>
          <component :is="menu.meta.icon"></component>
        </el-icon>
        <template #title>{{ $t(`common.menus.${menu.name as string}`)}}</template>
        <template v-if="activeIndex === `${index}`">
          <span class="top-border"></span>
          <span class="bottom-border"></span>
          <span class="left-border"></span>
          <span class="right-border"></span>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const menuRoutes = router.getRoutes().filter(r => r.meta.menu);

const isCollapse = ref(false);

const activeIndex = ref('0');

const onArithmeticManagementItemClick = () => {
  router.push({
    name: 'arithmeticManagement'
  });
};

</script>

<style lang="scss" scoped src="./LayoutSideMenu.scss"></style>

