import type { GetUserInfoResponseData } from '@/interface/user/getUserInfo';
import { getUserInfo } from '@/utils/auth';
import { defineStore } from 'pinia';
const userInfo = getUserInfo();
export const initUserInfo = () => {
  return {
    'email': '',
    'icon': '',
    'id': '',
    'name': '',
    'token': '',
    'total_cu': 0
  };
};
export const useUserInfoStore = defineStore('userInfo', {
  state: () : {
    userInfo: GetUserInfoResponseData
  } => {
    return {
      userInfo: userInfo || initUserInfo()
    };
  }
});
