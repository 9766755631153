export const joinBeta = {
  title: '加入测试计划',
  desc: '通过我们的革命性解决方案释放显卡的强大潜力。<br>我们很高兴推出一种改变游戏规则的方式来充分发挥显卡的潜力。此外，您还可以获得<strong>免费CU</strong>来访问强大的GPU资源。',
  form: {
    email: '电子邮件',
    emailPlaceholder: '请输入电子邮件地址',
    emailRequired: '请输入电子邮件地址',
    emailFormatError: '请输入正确的电子邮件地址',
    firstName: '姓氏',
    firstNamePlaceholder: '请输入你的姓氏',
    firstNameRequired: '请输入你的姓氏',
    lastName: '名字',
    lastNamePlaceholder: '请输入你的名字',
    lastNameRequired: '请输入你的名字',
    career: '职业',
    careerPlaceholder: '请选择你的职业',
    careerRequired: '请选择你的职业',
    org: '公司/组织',
    orgPlaceholder: '请输入公司或组织名称',
    joinReason: '你为什么有兴趣加入NiceGPU测试计划',
    joinReasonPlaceholder: '你为什么有兴趣加入NiceGPU测试计划',
    joinReasonRequired: '你为什么有兴趣加入NiceGPU测试计划',
    submitRepeated: '此邮箱已存在，请勿重复提交！',
    fieldType: '（可选）',
    submit: '提交'
  },
  accountInfo: {
    submitSuccess: '欢迎加入NiceGPU测试计划！<br>在有可用算力资源情况下，我们会为您分配免费算力资源。<br>如暂时没有可用算力，请耐心等待。',
    note: '我们将以邮件的形式向你发送账号和密码，请注意查收。',
    userName: '用户名：',
    password: '密码：'
  }
};
