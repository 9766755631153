export const dashboard = {
  titlePre: 'Providing',
  title: 'Unparalleled Computational',
  desc: 'NiceGPU is the market leader in low-cost cloud GPU rental. Use one simple interface to save 5-6X on GPU compute.',
  joinBeta: 'Join our Beta List',
  functions: {
    title: 'Docker Ecosystem',
    desc: 'Docker-based container and image deployment to get all the software you need up and running quickly.',
    features: {
      feature1Subject: 'Out of The Box Deployment',
      feature1Desc: 'Pre Configured Deployment of Your Favorite Template',
      feature2Subject: 'On-Demand GPU Access',
      feature2Desc: 'Instant and Permissionless access to the internet of GPUs with Industry Breaking Prices',
      feature3Subject: 'Better, Faster and Cheaper',
      feature3Desc: 'Industry Breaking Prices'
    }
  }
};
