<script setup lang="ts">
import svgLanguage from '@/assets/svg/svgLanguage.vue';
import { EnumLanguage } from '@/enums/language';
import i18n from '@/lang';
import { computed } from 'vue';
const onLanguageCommand = (lan: EnumLanguage) => {
  i18n.global.locale.value = lan.toLocaleLowerCase();
};

const currentLan = computed(() => {
  return i18n.global.locale.value;
});

</script>

<template>
  <el-dropdown :teleported="false" @command="onLanguageCommand">
    <el-icon>
      <svgLanguage></svgLanguage>
    </el-icon>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :class="{'is-active': currentLan === EnumLanguage.LAN_CHINESE.toLocaleLowerCase()}" :command="EnumLanguage.LAN_CHINESE">中文</el-dropdown-item>
        <el-dropdown-item :class="{'is-active': currentLan === EnumLanguage.LAN_EN_US.toLocaleLowerCase()}" :command="EnumLanguage.LAN_EN_US">English</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style lang="scss" scoped src="./ChangeLanguage.scss"></style>
