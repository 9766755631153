export const dashboard = {
  titlePre: '提供',
  title: '无与伦比的计算能力',
  desc: 'NiceGPU是低成本云GPU租赁市场的领导者。让算力使用更简单，并能节省5-6倍的GPU计算成本。',
  joinBeta: '加入我们的测试计划',
  functions: {
    title: 'Docker生态系统',
    desc: '基于Docker的容器化部署，以快速启动和运行所需软件。',
    features: {
      feature1Subject: '开箱即用的应用',
      feature1Desc: '预装丰富的GPU应用模板',
      feature2Subject: 'GPU按需使用',
      feature2Desc: '随时随地按需使用GPU，价格领先行业',
      feature3Subject: '更好、更快、更便宜',
      feature3Desc: '极简算力使用，极致节省成本'
    }
  }
};
