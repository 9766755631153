export const contactUs = {
  title: '联系我们',
  desc: '你对我们的服务有疑问或需要更多信息吗？<br>填写下面的表格，我们的团队将与你联系，提供帮助并回答你可能有的任何疑问。',
  form: {
    email: '电子邮件',
    emailPlaceholder: '请输入电子邮件地址',
    emailRequired: '请输入电子邮件地址',
    emailFormatError: '请输入正确的电子邮件地址',
    fullName: '姓名',
    fullNamePlaceholder: '请输入姓名',
    fullNameRequired: '请输入姓名',
    message: '消息',
    messagePlaceholder: '请输入消息',
    messageRequired: '请输入消息',
    submitRepeated: '此邮箱已存在，请勿重复提交！',
    submitSuccess: '提交成功！我们稍后会与你联系。',
    submit: '提交'
  }
};
