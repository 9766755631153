import { arithmeticManagement } from './arithmetic-management-cn';
import { common } from './common-cn';
import { contactUs } from './contact-us-cn';
import { dashboard } from './dashboard-cn';
import { instanceDetail } from './instance-detail-cn';
import { joinBeta } from './join-beta-cn';
import { login } from './login-cn';
import { newService } from './new-service-cn';
export const cn = {
  arithmeticManagement,
  common,
  login,
  instanceDetail,
  newService,
  joinBeta,
  contactUs,
  dashboard
};
