export const joinBeta = {
  title: 'Join our Beta List',
  desc: 'Unlock the Power of Graphics Cards with Our Revolutionary Solution.<br>We\'re excited to introduce a game-changing way to unleash the full potential of graphics cards. Plus, get <strong>FREE credit</strong> to access powerful GPU resources.',
  form: {
    email: 'Email',
    emailPlaceholder: 'Please enter your email address',
    emailRequired: 'Please enter your email address',
    emailFormatError: 'Please enter the correct email address',
    firstName: 'First Name',
    firstNamePlaceholder: 'Please enter your first name',
    firstNameRequired: 'Please enter your first name',
    lastName: 'Last Name',
    lastNamePlaceholder: 'Please enter your last name',
    lastNameRequired: 'Please enter your last name',
    career: 'Profession',
    careerPlaceholder: 'Please select your profession',
    careerRequired: 'Please select your profession',
    org: 'Organizational Affilication',
    orgPlaceholder: 'Please enter the organizational affilication',
    joinReason: 'Why are you interested in joining the NiceGPU beta program',
    joinReasonPlaceholder: 'Why are you interested in joining the NiceGPU beta program',
    joinReasonRequired: 'Why are you interested in joining the NiceGPU beta program',
    submitRepeated: 'This email already exists, please do not submit again!',
    fieldType: '(If any)',
    submit: 'Submit'
  },
  accountInfo: {
    submitSuccess: 'Welcome to the NiceGPU testing program!<br>If computational resources are available, we will allocate them to you for free.<br>If no resources are currently available, please wait patiently.',
    note: 'We will send you the account and password via email. Please check your inbox.',
    userName: 'Username:',
    password: 'Password:'
  }
};
