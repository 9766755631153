import service from '@/utils/request';
import {
  env
} from '@/setting';
import type { LoginRequestData, LoginResponse } from '@/interface/user/login';

export const login = (loginRequestData: LoginRequestData): Promise<LoginResponse> => {
  return service({
    url: `${env[window.location.host].api}/user/login`,
    data: loginRequestData,
    method: 'post'
  });
};
