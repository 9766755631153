export const contactUs = {
  title: 'Contact Us',
  desc: 'Do you have questions or need more information about our services? <br>We\'re here to help! Fill out the form below, and our team will get in touch with you to provide assistance and answer any queries you might have.',
  form: {
    email: 'Email',
    emailPlaceholder: 'Please enter your email address',
    emailRequired: 'Please enter your email address',
    emailFormatError: 'Please enter the correct email address',
    fullName: 'Name',
    fullNamePlaceholder: 'Please enter your name',
    fullNameRequired: 'Please enter your name',
    message: 'Message',
    messagePlaceholder: 'Please enter your message',
    messageRequired: 'Please enter your message',
    submitRepeated: 'This email already exists, please do not submit again!',
    submitSuccess: 'Submitted successfully! We will contact you later.',
    submit: 'Submit'
  }
};
