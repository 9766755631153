const TokenKey = 'NW-V2-Token';
const userInfoKey = 'NW-V2-UserInfo';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import type { LoginResponseData } from '@/interface/user/login';

export const getToken = () => {
  return localStorage.getItem(TokenKey);
};

export const setToken = (token: string) => {
  localStorage.setItem(TokenKey, token);
};

export const removeToken = () => {
  localStorage.removeItem(TokenKey);
};

export const getUserInfo = (): LoginResponseData | null => {
  if (!getToken()) { return null; }
  const userInfo = window.localStorage.getItem(userInfoKey);
  if (!userInfo) { return null; }

  try {
    const objStr = AES.decrypt(userInfo, getToken() as string).toString(CryptoJS.enc.Utf8);
    if (objStr) { return JSON.parse(objStr); } else return null;
  } catch (e) {
    return null;
  }
};

export const setUserInfo = (userInfo: LoginResponseData) => {
  window.localStorage.setItem(userInfoKey, AES.encrypt(JSON.stringify(userInfo), getToken() as string).toString());
};

export const removeUserInfo = () => {
  localStorage.removeItem(userInfoKey);
};
