import i18n from '@/lang';
import { computed } from 'vue';

export enum EnumStep {
  PREPARING_DATA = 'Preparing data',
  ANALYZING = 'Analyzing',
  LANGUAGE = 'Language',
  COMPLETED = 'Completed'
}

export interface StepOptionsItem {
  num: number;
  value: EnumStep;
  label: string;
}

export const useGetStepOptions = (lan?: boolean) => {
  return computed(() => {
    const result:Array<StepOptionsItem> = [];
    let initNum = 1;
    for (const i in EnumStep) {
      const val = EnumStep[i as keyof typeof EnumStep];
      if (!lan && EnumStep.LANGUAGE === val) continue;
      result.push({
        num: initNum++,
        value: val,
        label: i18n.global.t(`common.step.${val}`)
      });
    }
    return result;
  });
};
