import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken, getUserInfo, removeToken, removeUserInfo } from '@/utils/auth';
import i18n from '@/lang';
import svgManagementMenu from '@/assets/svg/svgManagementMenu.vue';

const router = createRouter({
  history: createWebHistory((import.meta as any).env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/hai/arithmetic-management'
    },
    {
      path: '/join-us',
      name: 'joinBeta',
      component: () => import('@/views/JoinBeta.vue')
    },
    {
      path: '/contact-us',
      name: 'contactUs',
      component: () => import('@/views/ContactUs.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboardView',
      component: () => import('@/views/DashboardView.vue')
    },
    {
      path: '/hai/arithmetic-management',
      name: 'arithmeticManagement',
      component: () => import('@/views/ArithmeticManagement.vue'),
      meta: { preload: true, menu: true, icon: svgManagementMenu }
    },
    {
      path: '/hai/new-service',
      name: 'newService',
      component: () => import('@/views/NewService.vue'),
      meta: { preload: true, backUrl: '/hai/arithmetic-management' }
    },
    {
      path: '/hai/instance-detail/:id',
      name: 'instanceDetail',
      component: () => import('@/views/InstanceDetail.vue'),
      props: true,
      meta: { preload: true, backUrl: '/hai/arithmetic-management' }
    }
  ]
});

export const whiteList = ['/login', '/join-us', '/contact-us', '/dashboard'];
const collectRedirectPath = (to: RouteLocationNormalized) => {
  if (window.localStorage && !whiteList.includes(to.path)) {
    window.localStorage.setItem('redirect', to.fullPath);
  }
};

router.beforeEach((to, from, next) => {
  // 收集redirect的路径
  collectRedirectPath(to);
  // 开始加载进度条
  NProgress.start();
  (window.document as any).title = i18n.global.t(`common.breadcrumbs.${to.name as string}`);
  // 获取token
  const token = getToken();
  if (token) {
    if (getUserInfo()?.total_cu === undefined) { // 兼容老数据
      removeUserInfo();
      removeToken();
      next({ path: '/dashboard' });
    } else {
      if (to.path === '/dashboard') {
        next({ path: '/' });
      } else {
        next();
      }
    }
    NProgress.done();
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/dashboard?redirect=${encodeURIComponent(to.fullPath)}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
