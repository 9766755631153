<template>
  <div class="user-login">
    <el-dialog v-model="loginVisible" width="auto">
      <el-form
        ref="ruleFormRef"
        style="max-width: 600px"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="auto"
        @submit.prevent
      >
        <div class="el-form-title">
          {{ $t('common.appName') }}
        </div>
        <div class="el-form-content">
          <el-form-item prop="account_name">
            <el-input @keyup.enter="submitForm(ruleFormRef)" :placeholder="$t('login.userNamePlaceholder')" v-model="ruleForm.account_name" clearable />
          </el-form-item>
          <el-form-item prop="account_pwd">
            <el-input
              @keyup.enter="submitForm(ruleFormRef)"
              v-model="ruleForm.account_pwd"
              :placeholder="$t('login.passwordPlaceholder')"
              type="password"
              clearable
            />
          </el-form-item>
          <el-form-item class="el-form-item-operation">
            <el-button type="primary" :loading="isLogin" @click="submitForm(ruleFormRef)">
              {{ $t('login.loginBtn') }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, watch } from 'vue';
import { ElMessage, type FormInstance, type FormRules } from 'element-plus';
import type { LoginRequestData } from '@/interface/user/login';
import { login } from '@/api/user/login';
import { EnumHttp } from '@/enums/httpEnum';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import { useRouter } from 'vue-router';
import { setToken, setUserInfo } from '@/utils/auth';
import i18n from '@/lang';
import { useUserInfoStore } from '@/stores/userInfo';
interface Prop {
  visible: boolean;
}
const props = withDefaults(defineProps<Prop>(), {

});
const loginVisible = ref(false);
watch(() => props.visible, () => {
  if (props.visible) loginVisible.value = true;
});

const emits = defineEmits(['onDialogClose']);
watch(() => loginVisible.value, () => {
  if (!loginVisible.value) emits('onDialogClose');
});

const userInfoStore = useUserInfoStore();

const ruleFormRef = ref<FormInstance>();

const validateName = (rule: any, value: any, callback: any) => {
  if (!value.trim()) {
    callback(new Error(i18n.global.t('login.userNameRequiredWords')));
  } else {
    callback();
  }
};
const validatePass = (rule: any, value: any, callback: any) => {
  if (!value.trim()) {
    callback(new Error(i18n.global.t('login.passwordRequiredWords')));
  } else {
    callback();
  }
};

const ruleForm = reactive<LoginRequestData>({
  account_name: '',
  account_pwd: ''
});

const rules = reactive<FormRules<typeof ruleForm>>({
  account_name: [{ validator: validateName, trigger: ['blur', 'change'] }],
  account_pwd: [{ validator: validatePass, trigger: ['blur', 'change'] }]
});

const redirect = ref();
const router = useRouter();
const isLogin = ref(false);
const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate(async(valid) => {
    if (valid) {
      const loginRequestData = _.cloneDeep(ruleForm);
      loginRequestData.account_pwd = CryptoJS.MD5(loginRequestData.account_pwd).toString().toLowerCase();
      isLogin.value = true;
      await login(loginRequestData).then(res => {
        if (res.code === EnumHttp.SUCCESS) {
          redirect.value = decodeURIComponent(new URLSearchParams(window.location.search).get('redirect') || '');
          redirect.value = redirect.value || window.localStorage.getItem('redirect') || '/';
          setToken(res.data.token);
          setUserInfo(res.data);
          userInfoStore.userInfo = res.data;
          ElMessage({
            type: 'success',
            message: i18n.global.t('login.loginSuccess')
          });
          loginVisible.value = false;
          router.push(redirect.value);
        } else if (res.code === EnumHttp.LOGIN_ERROR) {
          ElMessage({
            type: 'error',
            message: i18n.global.t('login.loginError')
          });
        }
      }).catch(err => {
        console.log(err);
      });
      isLogin.value = false;
    } else {
      console.log('error submit!');
    }
  });
};
</script>
<style scoped lang="scss" src="./UserLogin.scss"></style>
