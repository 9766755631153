import { createI18n } from 'vue-i18n';
import { cn } from './cn';
import { cnt } from './cn-t';
import { en } from './en';
import { EnumLanguage } from '@/enums/language';
import { getLanguage } from '@/utils/localStorage';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLanguage() || 'en-us',
  messages: {
    [EnumLanguage.LAN_CHINESE.toLocaleLowerCase()]: cn,
    [EnumLanguage.LAN_TRANDITIONAL_CHINESE.toLocaleLowerCase()]: cnt,
    [EnumLanguage.LAN_EN_US.toLocaleLowerCase()]: en
  }
});

export const onEufLangChange = (l: string) => {
  i18n.global.locale.value = l;
};

export default i18n;
