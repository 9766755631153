export enum EnumProfession {
  SOFTWARE_ENGINEER = 'Software Engineer',
  PRODUCT_MANAGER = 'Product Manager',
  DESIGNER = 'Designer',
  STUDENT = 'Student',
  OPERATIONS_STAFF = 'Operations Staff',
  MARKETING_PERSONNEL = 'Marketing Personnel',
  FREELANCER = 'Freelancer',
  OTHER = 'Other'
}
