import { EnumLanguage } from '@/enums/language';
import { EnumStep } from '@/enums/step';
export const common = {
  appName: 'NiceGPU',
  meta: {
    title: {
      'customer-review': 'Customer Review AI分析 - SellingPilot',
      'plugin-home': 'SellingPilot - Your AI-powered assistant for e-commerce.'
    }
  },
  language: {
    [EnumLanguage.LAN_EN_US]: '英文',
    [EnumLanguage.LAN_CHINESE]: '簡體中文',
    [EnumLanguage.LAN_TRANDITIONAL_CHINESE]: '繁體中文'
  },
  noRatingTooltip: '沒有客戶評論，無法進行分析。',
  step: {
    [EnumStep.PREPARING_DATA]: '準備數據中',
    [EnumStep.ANALYZING]: '分析中',
    [EnumStep.LANGUAGE]: '生成多語言',
    [EnumStep.COMPLETED]: '分析完成'
  },
  breadcrumbs: {
    arithmeticSupply: '算力提供',
    arithmeticTrade: '算力交易',
    arithmeticManagement: '算力管理',
    newService: '新建服务',
    authRedirect: '登录中...',
    login: '登录中...',
    instanceDetail: '实例详情'
  },
  noData: '暂时没有数据'
};
