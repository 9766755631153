import { defineStore } from 'pinia';

export interface InstanceDetailPage {
  instanceName: string;
}
export const useInstanceDetailPageStore = defineStore('instanceDetailPage', {
  state: () : InstanceDetailPage => {
    return {
      instanceName: ''
    };
  }
});
