export const login = {
  userNamePlaceholder: '请输入用户名',
  userNameRequiredWords: '请输入用户名',
  passwordPlaceholder: '请输入登录密码',
  passwordRequiredWords: '请输入登录密码',
  loginBtn: '登录',
  logoutBtn: '退出',
  loginError: '用户名或登录密码错误',
  loginSuccess: '登录成功',
  contactUs: {
    title: '联系我们'
  },
  joinBeta: {
    title: '加入我们的测试计划',
    content: {
      title: '<strong>通过我们的革命性解决方案释放显卡的强大潜力。</strong>',
      content: '我们很高兴推出一种改变游戏规则的方式来充分发挥显卡的潜力。此外，您还可以获得<strong>免费CU</strong>来访问强大的GPU资源。',
      end: '<strong>立即加入我们的测试计划！</strong>'
    },
    form: {
      emailPlaceholder: '请输入邮箱',
      joinBetaBtn: '加入测试',
      emailRequired: '请输入邮箱',
      emailFormatError: '请输入正确的邮箱地址',
      submitSuccess: '提交成功！',
      submitRepeated: '此邮箱已存在，请勿重复提交！'
    },
    accountInfo: {
      submitSuccess: '欢迎加入NiceGPU测试计划！<br>在有可用算力资源情况下，我们会为您分配免费算力资源。<br>如暂时没有可用算力，请耐心等待。',
      note: '请妥善保存下面的用户名和密码，因为它们只会显示一次。',
      submitRepeated: '此邮箱已存在，请勿重复提交！',
      userName: '用户名：',
      password: '密码：'
    }
  }
};
