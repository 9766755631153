import service from '../utils/request';
import {
  env
} from '@/setting';
import type { FeedbackData } from '@/interface/feedback';
const _API_BASE_URL = env[window.location.host].api;

export const feedback = (data: FeedbackData) => {
  return service({
    url: `${_API_BASE_URL}/user/feedback`,
    method: 'post',
    data
  });
};
